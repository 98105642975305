<template>
  <!-- BEGIN: Login AGREEMENT -->
  <div class="text-center intro-x text-slate-600 dark:text-slate-500">
    {{ $t("login_agreement_text") }}

    <a
      class="text-primary dark:text-slate-200"
      :href="runtimeConfig.public.termsOfUsageLink"
    >
      {{ $t("login_agreement_terms_of_use_link") }}
    </a>

    {{ $t("and") }}

    <a
      class="text-primary dark:text-slate-200"
      :href="runtimeConfig.public.privacyPolicyLink"
    >
      {{ $t("login_agreement_privacy_policy_link") }}
    </a>
  </div>
  <!-- END: Login AGREEMENT -->
</template>

<script setup lang="ts">
  import { useRuntimeConfig } from "#app";
  const runtimeConfig = useRuntimeConfig();
</script>
